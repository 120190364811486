(function ($) {

    $.fn.videotourdialog = function (options) {

        var self = $(this);

        var settings = $.extend({
            TourContent: [{
                "icon": "hand.svg",
                "title": "Welcome to YouLi",
                "youTubeVideo": "https://www.youtube.com/embed/KDmcfkxSRu4",
                "text": "It's easy to get started and affordable to scale your business to any size with YouLi."
            }],
            DefaultIconWidth: 70,
            DefaultIconHeight: 102,
            HiddenHandler: undefined,
        }, options);


        self.currentStep = 1;
        self.TourContent = settings.TourContent;
        self.TotalSteps = self.TourContent.length;

        function StopYouTubeVideos() {
            $(".yt_player_iframe").each(function () {
                this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
            });
        }

        function SetupStepContent(step) {

            let contentIndex = step - 1;
            if (self.TourContent[contentIndex]) {

                let stepContent = self.TourContent[contentIndex];
                if (stepContent) {
                    let icon = `/content/img/${stepContent.icon || 'hand.svg'}`;

                    //setting header icon
                    self.find('#PopupIcon').attr("src", icon);
                    if (stepContent.iconWidth) {
                        self.find('#PopupIcon').width(stepContent.iconWidth);
                    } else {
                        self.find('#PopupIcon').width(settings.DefaultIconWidth);
                    }

                    if (stepContent.iconHeight) {
                        self.find('#PopupIcon').height(stepContent.iconHeight);
                    } else {
                        self.find('#PopupIcon').height(settings.DefaultIconHeight);
                    }
                }
            }

            if (self.TotalSteps == 1) {
                self.find('#StartTourBtnContainer').removeClass('hidden');
                self.find('#StartTourBtn').html('Start Exploring');
                self.find('.middle-step').addClass('hidden');
            } else if (step == 1) {
                self.find('#StartTourBtnContainer').removeClass('hidden');
                self.find('#StartTourBtn').html('Start tour!');
                self.find('.middle-step').addClass('hidden');
            } else if (step < self.TotalSteps) {
                self.find('.middle-step').removeClass('hidden');
                self.find('#StartTourBtnContainer').addClass('hidden');
                self.find('#NextStepBtn').html('Next');
            } else if (step == self.TotalSteps) {

                self.find('.middle-step').removeClass('hidden');
                self.find('#StartTourBtnContainer').addClass('hidden');
                self.find('#NextStepBtn').html('End Tour');
            }

            $(self.find('.progress-dots')).empty();
            if (self.TotalSteps > 1) {

                if (self.currentStep == 1) {
                    $(self.find('.progress-dots')).append('<span class="rectangle"></span>');
                } else {
                    for (i = 1; i <= self.currentStep; i++) {
                        if (i == 1) {
                            $(self.find('.progress-dots')).append('<span class="rectangle flat-right"></span>');
                        } else if (i < self.currentStep) {
                            $(self.find('.progress-dots')).append('<span class="rectangle middle"></span>');
                        } else {
                            $(self.find('.progress-dots')).append('<span class="rectangle flat-left"></span>');
                        }
                    }
                }
                for (j = self.currentStep; j < self.TotalSteps; j++) {
                    $(self.find('.progress-dots')).append('<span class="ellipse"></span>');
                }
            }

        }

        function CreateStepsContent() {
            for (i = 1; i <= self.TotalSteps; i++) {
                let stepContent = self.TourContent[i - 1];
                let stepContentHtml = `<div class="body-content dialog-${i} cslide-slide">`;
                stepContentHtml += `  <h2 class='page-dialogue__title text-center title-alone popup-title'>${stepContent.title}</h2>`;
                stepContentHtml += '       <div class="row">';
                stepContentHtml += '          <div class="col-xs-12 col-sm-8 col-sm-offset-2">';
                stepContentHtml += '                <br />';
                stepContentHtml += '                <div class="embed-responsive embed-responsive-16by9">';
                stepContentHtml += `                   <iframe class="embed-responsive-item yt_player_iframe" src="${stepContent.youTubeVideo}?version=3&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen id="PopupVideo"></iframe>`;
                stepContentHtml += '                </div>';
                stepContentHtml += '          </div>';
                stepContentHtml += '       </div>';
                stepContentHtml += '       <div class="row">';
                stepContentHtml += '          <div class="col-xs-12 col-sm-offset-2 col-sm-8 text-center">';
                stepContentHtml += '            <br />';
                stepContentHtml += `            <span id="PopupText">${stepContent.text}</span>`;
                stepContentHtml += '         </div>';
                stepContentHtml += '       </div>';

                stepContentHtml += '</div>';
                self.find('.cslide-slides-container').append(stepContentHtml);
            }

        }

        $(function () {
            YouLi.Common.UI.Init();

            CreateStepsContent();
            SetupStepContent(1);

            self.find("#VideoTourImageContent").cslide();

            self.off("show.bs.modal").on("show.bs.modal", function () {
                CreateStepsContent();
                SetupStepContent(1);
            });


            self.off("click", ".next-btn").on("click", ".next-btn", function () {
                StopYouTubeVideos();
                if (self.TotalSteps == 1 || self.currentStep == self.TotalSteps) {
                    self.modal('hide');
                } else {
                    self.currentStep++
                    SetupStepContent(self.currentStep);
                }
            });

            self.off("click", ".prev-btn").on("click", ".prev-btn", function () {
                StopYouTubeVideos();
                self.currentStep--;
                SetupStepContent(self.currentStep);
            });


            self.off("hide.bs.modal").on("hide.bs.modal", function () {
                if (settings.HiddenHandler !== undefined) {
                    settings.HiddenHandler();
                }
                let iframeElm = self.find("iframe");
                if (iframeElm) {
                    iframeElm.attr("src", iframeElm.attr("src"));
                }
            });


        });

        return this;
    };

}(jQuery));